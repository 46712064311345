@import "./boxicons.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "variables";

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import "~bootstrap/scss/mixins.scss";
@import "~bootstrap/scss/bootstrap";

@import "custom/components";
@import "custom/plugins/switch";
@import "custom/plugins/responsive-table";
@import "custom/plugins/apexcharts";

.text-primary{color: $custom-primary !important}
.bg-white{background:#fff}
.rounded-check{border-radius:20px}


*{
  font-family: $font-family-sans-serif
}
body{background-color: #fff;}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column{
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}


.close{
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .5;
    width: 1em;
    height: 1em;
    z-index: 2;
    span {
      display: none;
    }
  }
  .modal-content{border-radius:0}
  .modal-open{
    overflow-y: none !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
  .modal-open .modal {
    margin: 0 auto;
}
  .wizard .actions {
    position: relative !important;
    display: block !important;
    text-align: right !important;
    width: 100% !important;
}
.wizard .actions>ul {
  display: block !important;
  text-align: right !important;
  padding-left: 0 !important;
}
.wizard .actions>ul>li {
  display: inline-block !important;
  margin: 0 .5em !important;
}

//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,.rdw-editor-main{
  border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
  text-transform: capitalize;
}
.dropzone:focus {
  outline: none !important ;
}
//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}
.invalid-feedback {
  margin-top: -10px !important;
  margin-bottom: 13px !important;
}


.verti-timeline{
    border-left: 3px dashed $gray-300;
    margin: 0 10px;
    .event-list{
        position: relative;
        padding: 0px 0px 40px 30px;

        .event-timeline-dot{
            position: absolute;
            left: -9px;
            top: 0px;
            z-index: 9;
            font-size: 16px;
        }
        .event-content{
            position: relative;
            border: 2px solid $border-color;
            border-radius: 7px;
        }

        &.active{
            .event-timeline-dot{
                color: $primary;
            }
        }

        &:last-child{
            padding-bottom: 0px;
        }
    }
}



.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: 1px 1px 15px 1px rgba(235,236,240,0.6);
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

// Breadcrumb item arrow
.breadcrumb-item {
    > a {
        color: $gray-700;
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }
}

.noti-icon {    
    .badge {
        position: absolute;
        top: -8px;
        left: 5px;
    }
}

.react-switch-bg{border: 1px solid rgb(204, 204, 204);}


.landing-img{
  padding:0; 
  width:100%;
  overflow:hidden;
  height: 100vh;
  position:absolute;
  top:0;
  opacity:0.7;
  left:0;
}

.form-control-login{
  &::placeholder {
    color:  $custom-primary;
    opacity: 1;
    font-weight:500;
  }

  &:-ms-input-placeholder {
   color:  $custom-primary;
   font-weight:500;
  }

  &::-ms-input-placeholder {
   color:  $custom-primary;
   font-weight:500;
  }
  background:#efeff4 !important
}


.profile-img{
  object-fit: cover;
  width: 100%;
  height: 100%; 
}

.white-sq-container{
  top: 50%;
  transform: translateY(-50%);
}


.header{
  .header-btn-menu{
    a{
      color:#495057;
      margin: auto 20px auto 0;
      text-decoration:none;
      display:flex;
      div{
        width:auto;
        &:last-child{
          margin-top:5px;
          margin-left:10px;
        }
      }
    }
  }

  .header-logo-div, .header-user-div{
    min-width:80px;
    div{
      margin-top:auto;
      margin-bottom:auto;
    }
    @media screen and (max-width: 768px) {
      max-width:100%;
      min-width:auto;
    }
  }

  .header-user-div{
    img{
      padding:10px;
    }
    @media screen and (max-width: 768px) {
      img{
        padding:0;
        min-width:30px;
      }
      .avatar-md{
        width:2.5rem;
        height:2rem;
        margin-right:5px;
      }
      font-size:11px;
    }
  }
  @media screen and (max-width: 768px) {
    .header-btn-menu{
      max-width:100%;
      font-size:11px;
      a{
        max-height:25px;
        display:block;
        text-align:center;
        margin-left:3px;
        margin-right:3px;
        div{
          &:last-child{
            margin-left:0px;
            margin-top:1px;
          }
        }
        svg{
          max-width:20px;
          max-height:15px;
        }
      }
    }
    .header-logo-div{
      img{
        height:auto !important;
        margin-top:3px;
      }
    }
  }
}

.row-md{
  @media screen and (max-width: 768px) {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);     
  }
}

.react-bootstrap-table-pagination-list{
  width:100%;
  ul{
    justify-content:center;
  }
  .pagination {
    .page-item {
      padding:4px 0;
      border-radius: 20px;
      width: 34px;
      justify-content: center;
      display: flex;
      height: fit-content;
      .page-link{
        background-color: transparent !important; 
        border:none !important; 
        border-radius:20px; 
        box-shadow: none !important;
        color: #495057;
        font-size:14px;
        line-height:1;
        margin:auto;
      }
      &:first-child, &:last-child{
        a{
          font-size: 20px;
          margin-top: -3px;
        }
      }
    }
    .active{
      background-color: $custom-primary !important;
      .page-link{
        color: #fff;
      }
    }
  }
}


.badge{min-width:80px;align-items:middle}
.badge-soft-draft{background:#F1B44C;}
.badge-soft-published{background:$blue;}
.badge-soft-archieved{background:$custom-primary;}


.table-container, .table-container-speciality, .table-container-product-docs{
  position:relative;
  @media screen and (max-width: 768px) {
      overflow-X:auto;
  }
  table{
    tr{
        td:last-child{
        white-space: nowrap;
      }
    }
  }
  .react-bootstrap-table-pagination{
    position: absolute;
    right: 0;
    left: 0;
  }
}


.table-container{
  table{
    min-width:650px;
  }
}

.table-container-speciality{
  table{
    min-width:700px;
    th{white-space:nowrap}
  }
}

.table-container-product-docs{
  table{
    min-width:320px;
    th{white-space:nowrap}
  }
}

.searchbar-input{
  max-width:260px;
  div{
    align-items: center;
    display: flex;
    padding-left:10px;
    padding-right:0px;
    background: #efeff4;
    border-radius: 1rem 0 0 1rem;
    border-right:none;
  }
  .form-control{
    border:none !important;
    border-radius: 0 1rem 1rem 0;
    &::placeholder {
      color:  #495057;
    }

    &:-ms-input-placeholder {
     color:  #495057;
    }

    &::-ms-input-placeholder {
     color:  #495057;
    }
  }
}

.header-class-dark-table{
  th{background:#F8F9FA}
}

.products-table{
  th:nth-child(4), td:nth-child(4){
    max-width:80px;
    width:80px;
  }
}

.file-drop{
  cursor:pointer;
  border: 1px dashed #CED4DA;
  height:100px;
  border-radius:5px;
}
.file-drop-target{
  width: 100%;
  height: 100%;
  text-align:center;
  display:flex;
  div{
    pointer-events:none;
    align-items:center;
    margin:auto
  }
}
.file-drop > .file-drop-target.file-drop-dragging-over-target {
  box-shadow: 0 0 13px 3px #f4f4f4;
  background:#f4f4f4 !important;
}

.basic-details-container{
  background: #f4f4f4;
  border-radius:5px;
  .basic-details-btn-grp{
    
     @media screen and (max-width: 768px) {
      margin-top:1rem;
    }
  }
}

.document-edit-table{
  tr{margin-top:1rem;}
  td, th{
    border: 12px solid transparent;
  }
  i{
    font-size:1.3rem;
  }
}

.dark-bg{background: #f4f4f4;}

.clickable{cursor:pointer}
.fw-semi-bold{font-weight:500}
table{
  tr{
    th{
      font-weight:600
    }
    td{
      vertical-align:middle
    }
  }
}

.user-table{
  td{
    height:75px;
  }
}

.header .user-avatar-div{
  padding:10px;
}
.user-avatar-div{ 
  display:flex;
  div{
    background:#ccc;
    justify-content:center;
    align-items:center;
    display:flex;
    border-radius:50px;
    line-height:10px;
    height:50px;
    width:50px;
    font-size:20px;
    font-weight:500;
  }
}


.modal, .modal-dialog, modal-content{
  outline:none !important;
  box-shadow: none  !important;
}